import React from 'react';
import { CardMedia, Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import { isEmpty } from 'lodash/fp';

import SEO from '../components/seo';
import { ResetPasswordForm } from '../components/Auth';
import { Header, Content } from '../components/layouts';
import logoPath from '../images/logo.png';
import { auth } from '../state';
import { PreLoader } from '../components/custom';

const ResetPassword = () => {
  const user = useSelector((state) => auth.selectors.selectUser(state));

  React.useEffect(() => {
    if (isEmpty(user)) {
      navigate('/');
    }
  });

  if (isEmpty(user)) {
    return <PreLoader />;
  }

  return (
    <>
      <SEO title="Reset Password" />
      <Header noMenu backArrow href="/forgot-password" />
      <Content md={4} alignMiddle>
        <Box pb={2} align="center">
          <Link to="/">
            <CardMedia
              src={logoPath}
              component="img"
              alt="Pintuna logo"
              style={{ width: '200px' }}
            />
          </Link>
        </Box>
        <Box py={2} align="center">
          <Typography variant="body2" color="textSecondary">
            Please enter security code from your email.
          </Typography>
        </Box>
        <ResetPasswordForm />
      </Content>
    </>
  );
};

export default ResetPassword;
